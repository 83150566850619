@import "../lib/mixins";

$black: #000;
$white: #fff;
$blue: #254F90;
$dark-blue: #032e70;
$red: #f8372e;
$rose: #EC9B88;
$yellow: #ffff00;
$purple: #8C86DE;

$nav-background: $dark-blue;

// Scrollbars
$scrollbar-width: 23px;
$scrollbar-thumb: #EBEBEB;

@mixin scrollbar-track {
  background: $blue;
  background: linear-gradient(54deg, #{$blue} 6%, #{$dark-blue} 75%);
}

// $menu-button-ratio: 1.2672064777;

$mobile-menu-button-height: 80px;

$header-contact-width: 100px;
$header-contact-width-2200: 200px;

$menu-button-width: 169.8056680118px;
$menu-button-height: 134px;

$menu-button-width-900: 114.048582993px;
$menu-button-height-900: 90px;

$menu-button-width-1024: 126.72064777px;
$menu-button-height-1024: 100px;

$menu-button-width-1300: 169.8056680118px;
$menu-button-height-1300: 134px;

$menu-button-width-1415: 152.064777324px;
$menu-button-height-1415: 120px;

// largest 32-bit integer
$overlay-zindex: 2147483647;
$disabled-scroll-zindex: $overlay-zindex - 1;

// Structural constants and helper mixins

$min-fluid-width: 320px;
$max-fluid-width: 4000px;

$max-desktop-small: 1154px;
$max-desktop-small-height: 735px;

$design-width: 1440px; // reference width from the design
$design-gutter: 0px; // reference grid does have a gutter but it's ignored
$max-mobile: 959px; // includes ipad portrait if bigger than $max-phone
$max-phone: 767px;
$total-cols: 18;
$col-width: percentage(1 / $total-cols);
$default-gutter: percentage($design-gutter / $design-width);
$fallback-width: 1200px; // used in place of 100vw where vw not supported
$mobile-padding: 25px;

@mixin grid-container {
  max-width: $max-fluid-width;
  min-width: $min-fluid-width - $mobile-padding * 2;
  margin: 0 auto;
}

@mixin gutters($gutter: $default-gutter, $parent-cols: $total-cols) {
  // Add left/right margin according to the grid gutter width.
  $factor: $total-cols / $parent-cols;
  margin-left: $gutter * $factor;
  margin-right: $gutter * $factor;
}

@function col-width(
  $cols,
  $parent-cols: $total-cols,
  $gutter: $default-gutter
) {
  $factor: $total-cols / $parent-cols;
  @return ($cols * $col-width - $gutter * 2) * $factor;
}

@mixin cols($cols, $parent-cols: $total-cols, $gutter: $default-gutter) {
  // Set width to the specified number of cols, with gutters.
  width: col-width($cols, $parent-cols, $gutter);
  @include gutters($gutter, $parent-cols);
}

@mixin ib-grid($args...) {
  // wrapper for cols() which also makes the element an inline-block column
  display: inline-block;
  vertical-align: top;
  @if length($args) > 0 {
    @include cols($args...);
  }
}

@mixin prefix(
  $cols: 0,
  $parent-cols: $total-cols,
  $gutter: $default-gutter,
  $prop: margin
) {
  // Add left margin of the specified number of cols
  $factor: $total-cols / $parent-cols;
  #{$prop}-left: (($col-width * $cols) + $gutter) * $factor;
}

@mixin suffix(
  $cols: 0,
  $parent-cols: $total-cols,
  $gutter: $default-gutter,
  $prop: margin
) {
  // Add right margin of the specified number of cols
  $factor: $total-cols / $parent-cols;
  #{$prop}-right: (($col-width * $cols) + $gutter) * $factor;
}

@function grid-px($cols, $gutter: $default-gutter) {
  // Calculate the pixel width of a fluid grid element, at $design-width
  @return round(($cols / $total-cols - 2 * $gutter / 100%) * $design-width);
}

@mixin vw-prop(
  $prop,
  $design-val: 0,
  $min-width: ($max-mobile + 1),
  $max-width: $max-fluid-width,
  $val: 0
) {
  // Set the value of $prop such that the value is fluid when the browser
  // width is within $min-width and $max-width, and fixed outside this range.
  // When the browser width is within the range, use vw, and outside use px.
  // If $design-val is set, use it to derive $val, as a percentage (in vw) of
  // $design-width. Otherwise, use $val to derive the min and max pixel values.

  @if ($design-val != 0) {
    // assume $design-val is in px
    $val: $design-val / $design-width * 100;
  }

  @if $allow-mq {
    @include restricted-vw-property($prop, $val, $min-width, $max-width);
  } @else {
    #{$prop}: $val * $fallback-width / 100;
  }
}

@mixin desktop-large-only {
  @if $allow-mq {
    @media screen and (min-width: #{$design-width + 1}) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin desktop-medium-only {
  @if $allow-mq {
    @media screen and (min-width: #{$max-desktop-small + 1}) and (max-width: $design-width) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin desktop-small-only {
  @if $allow-mq {
    @media screen and (min-width: #{$max-mobile + 1}) and (max-width: $max-desktop-small) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin desktop-small-height-only {
  @if $allow-mq {
    @media screen and (min-width: #{$max-mobile + 1}) and (max-height: $max-desktop-small-height) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin desktop-only {
  @if $allow-mq {
    @media screen and (min-width: #{$max-mobile + 1}) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin mobile-only {
  @if $allow-mq {
    @media screen and (max-width: $max-mobile) {
      @content;
    }
  }
}

@mixin tablet {
  // target mobile devices larger than $max-phone - should always be
  // nested within a mobile-only block
  @media (min-width: $max-phone + 1px) {
    @content;
  }
}

// Miscellaneous constants and helpers
// see also constants.js

$header-height: 200px;
$product-content-cols: 17;
$product-sidebar-cols: $total-cols - $product-content-cols; // 7
$mobile-image-max: 575px; // approx 80% of 768px - tablet portrait
$mobile-text-max: 450px;

@mixin underline($color: currentColor, $weight: 0.08em, $offset: 0.02em) {
  text-decoration: none;
  background-image: unquote("linear-gradient(") to top, transparent $offset,
    $color $offset, $color ($offset + $weight),
    transparent ($offset + $weight) unquote(")");
}
@mixin no-underline {
  background-image: none;
}

@mixin hover-state($touch-default: false) {
  /* Enable hover states on non-touch devices only. If $touch-default,
     default to the hovered state for touch devices.
     Requires Modernizer.touchevents */

  .no-touchevents &:hover {
    @content;
  }
  @if $touch-default {
    .touchevents & {
      @content;
    }
  }
}

@mixin spill($direction, $color: $black, $sel: null) {
  /* Creates a 'spill' element out to the edge of the viewport, of the same
     height as the parent, which can't be position: static. */

  @media (min-width: #{$max-fluid-width + 1}) {
    $prop: null;
    @if ($direction == right) {
      $prop: left;
      @if not $sel {
        $sel: "&:after";
      }
    } @else {
      $prop: right;
      @if not $sel {
        $sel: "&:before";
      }
    }

    #{$sel} {
      content: "";
      background: $color;
      position: absolute;
      top: 0;
      bottom: 0;
      width: calc(50vw - #{$max-fluid-width / 2});

      #{$prop}: 100%;
    }
  }
}

@mixin small-flickity-dots {
  .dot {
    width: 10px;
    height: 10px;
    border: 0;
    background: $white;
    opacity: 0.5;
    &.is-selected {
      opacity: 1;
    }
  }
}
