@import 'c/src/constants.scss';

html {
  // User can't scroll, scrollbar is visible, which avoids content jumping to
  // the right.
  &.disable-scroll {
    // Make unscrollable, while preserving scroll position and still allowing
    // it to be changed via js.
    overflow-y: hidden;
    position: absolute;

    // Make sure the user can see (and only see) one disabled scrollbar.
    body::after {
      bottom: 0;
      content: '';
      height: 100%;
      position: fixed;
      right: 0;
      top: 0;
      z-index: $disabled-scroll-zindex;

      @media screen and (min-width: 768px) {
        overflow-y: scroll;
      }
    }

    // Show the scrollbar on body and any elements with class fixer instead,
    // to prevent any content from jumping due to a change in width or right.
    // The main risk is that some browser will not respect overflow: hidden
    // since it's on the html element, and either still allow scrolling or
    // render a disabled scrollbar anyway (therefore giving us a double
    // scrollbar), but I have successfully tested this on IE8 - 11, IE Edge 13,
    // Firefox (Mac and Windows), Chrome (Mac and Windows), Safari,
    // Mobile Safari, and Android.
    body,
    .fixer {
      @media screen and (min-width: 768px) {
        overflow-y: scroll;
      }
    }
  }

  // User can't scroll and scrollbar disappears. Typically for use in
  // conjunction with a full screen, scrollable overlay.
  &.hide-scroll {
    overflow-y: hidden;
  }
}
